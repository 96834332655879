/*
 * @Author: 冯杰
 * @Date: 2021-10-14 16:14:56
 * @LastEditTime: 2021-12-10 11:38:22
 * @FileName: 终端门店服务合同列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'TerminalContractList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/web/contractSign/findByConditions',
      requestType: 'GET',
      params: {
        userType: '2',
      },
    };
  },
  components: {
    Modal,
  },
  methods: {
    // checkboxChange(type, value) {
    //   this.formData[type] = value;
    //   console.log(this.formData);
    //  },
    modalClick({
      val,
      row,
    }) {
      let urls = '';
      let text = '';
      this.formConfig = {
        ...this.formConfig,
      };
      if (val.code === 'view') {
        this.getContractUrl(row.contractId);
      } else if (val.code === 'headPhotoPathUrl') {
        window.open(row.headPhotoPathUrl);
      } else if (val.code === 'download_idc') {
        urls = '/cps/v1/zhangfangyun/contractSign/downLoadContractIdentification';
        text = '批量下载身份证';
        // console.log(this.selectRow.length)

        if (!this.selectRow.length) return this.$message.warning('至少选择一条合同');
        const datas = [];
        this.selectRow.map((item) => {
          const data = item;
          datas.push(item.headPhotoPathUrl);
          return data;
        });
        this.operationClick(urls, text, datas);
      } else if (val.code === 'download_contract') {
        urls = '/cps/v1/zhangfangyun/contractSign/downLoadContractByContractIds';
        text = '批量合同下载';
        // console.log(this.selectRow.length)

        if (!this.selectRow.length) return this.$message.warning('至少选择一条合同');

        const datas = [];
        this.selectRow.map((item) => {
          const data = item;
          datas.push(item.contractId);
          return data;
        });

        this.operationClick(urls, text, datas);
      }
    },
    setColumn(col) {
      const rowData = col;
      if (rowData.field === 'headPhotoPathUrl') {
        rowData.type = 'html';
        rowData.formatter = ({
          row,
        }) => `<a href=${row.headPhotoPathUrl}>下载</a>`;
      }

      if (rowData.field === 'terminalCustomerOrganization') {
        rowData.search = 'true';
        rowData.type = 'select';
        rowData.apiUrl = '/mdm/baseTreeController/customerOrgTree';
        rowData.optionsKey = {
          label: 'name',
          value: 'code',
        };
        rowData.isSearch = true;
        rowData.paramsName = 'name';
        rowData.paramsFixed = {
          enableStatus: '009',
        };
      }
      // 所属组织
      if (rowData.field === 'terminalOrgName') {
        rowData.search = 'true';
        rowData.type = 'select';
        rowData.apiUrl = '/mdm/baseTreeController/orgTree';
        rowData.optionsKey = {
          label: 'name',
          value: 'code',
        };
        rowData.isSearch = true;
        rowData.paramsName = 'name';
        rowData.paramsFixed = {
          enableStatus: '009',
        };
      }
      return rowData;
    },
    getContractUrl(id) {
      request
        .get('/cps/v1/zhangfangyun/contractSign/findByContractId', {
          contractId: id,
        })
        .then((res) => {
          console.log('res', res);
          if (!res.success) return;
          this.url = res.result.viewUrl;
          window.open(res.result.viewUrl);
        });
    },
    operationClick(urls, text, params) {
      request.post(urls, params).then((res) => {
        if (res.success) {
          // 文件下载
          const aLink = document.createElement('a');
          aLink.download = text;
          aLink.href = res.result;
          document.body.appendChild(aLink);
          aLink.click();
          URL.revokeObjectURL(aLink.href);
          document.body.removeChild(aLink);

          this.$message({
            type: 'success',
            message: `${text}成功`,
          });
        }
      });
    },
  },
  created() {
    this.getConfigList('terminal_contract_list');
  },
};
